import * as types from "../../actionType";
import axios from "axios";
import { baseUrl, bearertoken } from "../../../Utiles/Helper";
import Cookies from "js-cookie";
import WarningImg from "../../../images/warning.png";
import { toast } from "../../../UIComponents/Toast";
// import * as FileSaver from 'file-saver';
// import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { client } from "../../AxiosInterceptor";

export const srfExFactoryDate = (payload) => ({
  type: types.SRF_EXFACTORY_DATE,
  payload,
});
export const srfRowExport = (payload) => ({
  type: types.EXPORT_ROW_DATA,
  payload,
});
export const srfDisableToggle = (payload) => ({
  type: types.SRF_DISABLE_TOGGLE,
  payload,
});
export const resetSrfSearchData = (payload) => ({
  type: types.RESET_SRF_SEARCH_DATA,
  payload,
});
export const srfRequestData = (data) => ({
  type: types.REQUEST_SECTION_RESET,
  payload: data,
});
export const resetSrfFields = (data) => ({
  type: types.RESET_SRF_FIELDS,
  payload: data,
});
export const resetSrfList = (data) => ({
  type: types.RESET_SRF_LIST,
  payload: data,
});
const srfListLoading = () => ({
  type: types.GET_SRF_LIST_LOADING,
});

const srfListSuccess = (data) => ({
  type: types.GET_SRF_LIST_SUCCESS,
  payload: data,
});
const srfListFail = (error) => ({
  type: types.GET_SRF_LIST_FAIL,
  payload: error,
});
const srfSaveLoading = () => ({
  type: types.SRF_SAVE_LOADING,
});

const srfSaveSuccess = (data) => ({
  type: types.SRF_SAVE_SUCCESS,
  payload: data,
});
const srfSaveFail = (error) => ({
  type: types.SRF_SAVE_FAIL,
  payload: error,
});
const multiSrfSaveLoading = () => ({
  type: types.MULTI_SRF_LOADING,
});

const multiSrfSaveSuccess = (data) => ({
  type: types.MULTI_SRF_SUCCESS,
  payload: data,
});
const multiSrfSaveFail = (error) => ({
  type: types.MULTI_SRF_FAIL,
  payload: error,
});

const srfSearchLoading = () => ({
  type: types.SRF_SEARCH_FILTER_LOADING,
});

const srfSearchSuccess = (data) => ({
  type: types.SRF_SEARCH_FILTER_SUCCESS,
  payload: data,
});
const srfSearchFail = (error) => ({
  type: types.SRF_SEARCH_FILTER_FAIL,
  payload: error,
});
const srfEditLoading = () => ({
  type: types.SRF_EDIT_LOADING,
});

const srfEditSuccess = (data) => ({
  type: types.SRF_EDIT_SUCCESS,
  payload: data,
});
const srfEditFail = (error) => ({
  type: types.SRF_EDIT_FAIL,
  payload: error,
});

const srfSendSampleRequestLoading = () => ({
  type: types.SRF_SEND_SAMPLE_REQUEST_LOADING,
});

const srfSendSampleRequestSuccess = (data) => ({
  type: types.SRF_SEND_SAMPLE_REQUEST_SUCCESS,
  payload: data,
});
const srfSendSampleRequestFail = (error) => ({
  type: types.SRF_SEND_SAMPLE_REQUEST_FAIL,
  payload: error,
});

const srfDeleteColorCodeLoading = () => ({
  type: types.DELETE_SRF_COLOR_LOADING,
});

const srfDeleteColorCodeSuccess = (data) => ({
  type: types.DELETE_SRF_COLOR_SUCCESS,
  payload: data,
});
const srfDeleteColorCodeFail = (error) => ({
  type: types.DELETE_SRF_COLOR_FAIL,
  payload: error,
});
const srfExportLoading = () => ({
  type: types.SRF_EXPORT_LOADING,
});

const srfExportSuccess = (data) => ({
  type: types.SRF_EXPORT_SUCCESS,
  payload: data,
});
const srfExportFail = (error) => ({
  type: types.SRF_EXPORT_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASEURL;

export const srfTableListData = (brand, style, factory, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `srf/list?brand=${brand}&factory=${factory}&season=${season}&style=${style}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfListLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfListSuccess(resp.data));
      })
      .catch((error) => {
        if (error?.response?.data?.error_desc) {
          toast.error(error?.response?.data?.error_desc);
        } else {
          toast("Please select Style, Factory & Season", {
            icon: (
              <img src={WarningImg} alt="warning-icon" height={25} width={25} />
            ),
          });
        }
        dispatch(srfListFail(error));
      });
  };
};

export const srfTableSaveData = (
  style,
  season,
  season_id,
  brand,
  factory,
  color_code,
  request_no,
  req_exfactory,
  RU,
  s,
  m,
  l,
  b,
  g,
  x,
  ys,
  ym,
  yl,
  rowDataForApi
) => {
  const srfSearch = JSON.parse(localStorage.getItem("SrfSearchData"));
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/details/srf_save/?style=${style}&season=${season}&season_id=${season_id}&brand=${brand}&factory=${factory}&color_code=${color_code}&request_no=${request_no}&req_exfactory=${req_exfactory}&RU=${RU}&s=${s}&m=${m}&l=${l}&b=${b}&g=${g}&x=${x}&ys=${ys}&ym=${ym}&yl=${yl}`,
      style,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfSaveLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfSaveSuccess(resp.data));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brand,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        toast.success("Request is saved,Sample not sent!");
        // location.reload()
      })
      .catch((error) => {
        dispatch(srfSaveFail(error));
        toast.error(
          error?.data?.info
            ? error?.data?.info
            : error?.response?.data?.error
            ? error?.response?.data?.error
            : "Couldn't Save the Data"
        );
      });
  };
};

export const multiSrfSaveData = (data, rowDataForApi) => {
  console.log("data,rowDataForApi", data, rowDataForApi);
  const srfSearch = JSON.parse(localStorage.getItem("SrfSearchData"));
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/details/multi_srf_save/`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(multiSrfSaveLoading());
    client(Config)
      .then((resp) => {
        dispatch(multiSrfSaveSuccess(resp.data));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brand,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        toast.success("Requests are saved,Samples not sent!");
        localStorage.setItem("RequestSend", true);
        location.reload();
      })
      .catch((error) => {
        dispatch(multiSrfSaveFail(error));
        console.log("error bulk save", error);
        toast.error(
          error?.data?.info ? error?.data?.info : "Could'nt upload Data"
        );
      });
  };
};

export const srfTableSearch = (brand, style, factory, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `srf/search?brand=${brand}&style=${style}&factory=${factory}&season=${season}`,
      style,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfSearchLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfSearchSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(srfSearchFail(error));
        dispatch(resetSrfList());
        localStorage.setItem(
          "DashboardData",
          JSON.stringify({
            brand: "",
            style: "",
            factory: "",
            season: "",
          })
        );
        toast.error("Data not found");
      });
  };
};

export const srfSendSampleRequest = (data, rowDataForApi) => {
  const srfSearch = JSON.parse(localStorage.getItem("SrfSearchData"));
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/factory/send_samples`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfSendSampleRequestLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfSendSampleRequestSuccess(resp.data));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brandForApi,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        // localStorage.setItem("RequestSend",false)
        toast.success(resp?.data?.info);
        dispatch(srfRequestData(false));
        localStorage.setItem("SampleNotes", "");
      })
      .catch((error) => {
        dispatch(srfSendSampleRequestFail(error));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brandForApi,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        toast.error(
          error?.response?.data?.info
            ? error?.response?.data?.info
            : error?.message
            ? "Server Error"
            : error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt send Sample",
          {
            duration: 5000,
          }
        );
        dispatch(srfRequestData(true));
      });
  };
};
export const srfListEdit = (
  style,
  season,
  season_id,
  brand,
  factory,
  color_code,
  request_no,
  RU,
  s,
  m,
  l,
  b,
  g,
  x,
  ys,
  ym,
  yl,
  req_exfactory,
  rowDataForApi
) => {
  const srfSearch = JSON.parse(localStorage.getItem("SrfSearchData"));
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/details/srf_edit/?style=${style}&season=${season}&season_id=${season_id}&brand=${brand}&factory=${factory}&color_code=${color_code}&request_no=${request_no}&RU=${RU}&s=${s}&m=${m}&l=${l}&b=${b}&g=${g}&x=${x}&ys=${ys}&ym=${ym}&yl=${yl}&req_exfactory=${req_exfactory}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfEditLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfEditSuccess(resp.data));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brand,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        const responseData = resp.data.info;
        const finalData =
          responseData.charAt(0).toUpperCase() + responseData.slice(1);
        toast.success(finalData);
        // location.reload()
      })
      .catch((error) => {
        dispatch(srfEditFail(error));
        toast.error(
          error?.data?.info
            ? error?.data?.info
            : error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt edit the Data"
        );
      });
  };
};

export const srfExport = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `srf/export`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
      responseType: "arraybuffer",
    };
    dispatch(srfExportLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfExportSuccess(resp.data));
        var blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var fileName = "SRF Export.xlsx";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        dispatch(srfExportFail(error));
        toast.error("No Data Found");
      });
  };
};
export const srfDeleteColorCode = (data, rowDataForApi) => {
  const srfSearch = JSON.parse(localStorage.getItem("SrfSearchData"));
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `delete/style/color`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srfDeleteColorCodeLoading());
    client(Config)
      .then((resp) => {
        dispatch(srfDeleteColorCodeSuccess(resp.data));
        srfSearch
          ? dispatch(
              srfTableSearch(
                srfSearch?.brand,
                srfSearch?.style,
                srfSearch?.factory,
                srfSearch?.season
              )
            )
          : dispatch(
              srfTableListData(
                rowDataForApi?.brand,
                rowDataForApi?.style,
                rowDataForApi?.factory,
                rowDataForApi?.season
              )
            );
        toast.success(
          `Deleted Request no : ${data?.reqno}, style : ${data?.style}, color : ${data?.color}, vendor : ${data?.factory},season : ${data?.season}`
        );
      })
      .catch((error) => {
        dispatch(srfDeleteColorCodeFail(error));
        toast.error("No Data Found");
      });
  };
};
